/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import gql from 'graphql-tag';


/* -------------------------------------------------------------------------- */
/*                                  FRAGMENTS                                 */
/* -------------------------------------------------------------------------- */
export const TranslationFragment = gql`
  fragment TranslationFragment on Translation {
    phrase
    translated
    tags
    id
  }
`;
