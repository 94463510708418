import React from 'react';


const MovieIcons: React.FC<{ iconName?: string }> = ({ iconName }) => {
    switch (iconName) {
        case 'Pet care':
            return (
                <svg width="31" height="29" viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.31583 2.9676C6.31432 2.96296 6.31285 2.9583 6.31143 2.95363L6.31583 2.9676ZM7.00365 5.07756L9.36573 12.3223C9.53318 12.8359 10.0124 13.1835 10.553 13.1835H20.6671C21.208 13.1835 21.6875 12.8354 21.8546 12.3214L24.2092 5.08036L26.5719 12.3463L28.1006 16.8687L20.7277 20.2762L15.61 25.9388L10.0107 20.4787L3.1679 16.9039L7.00365 5.07756ZM28.2805 16.9616L28.1798 16.8232C28.1936 16.8053 28.2104 16.7897 28.2296 16.7773L28.3382 16.9449L28.2805 16.9616ZM28.3095 17.0014L28.9082 17.8246L28.3631 16.9833L28.3095 17.0014ZM29.6203 18.8498L22.6691 21.9787L16.3441 28.4903C15.9064 28.8082 15.3136 28.8082 14.8759 28.4903L8.88574 22.7287L1.57776 18.8338L1.57207 18.8297C1.18155 18.5427 0.891885 18.1397 0.744503 17.6783C0.597625 17.2185 0.599562 16.7241 0.749965 16.2655L5.31978 2.18079C5.32684 2.15908 5.3345 2.13757 5.34274 2.11628C5.44228 1.85911 5.60051 1.62873 5.80484 1.44344C6.13358 1.14445 6.56217 0.978706 7.00679 0.978706C7.45244 0.978706 7.88198 1.14521 8.21102 1.44551C8.21969 1.45343 8.22825 1.46147 8.2367 1.46962C8.44969 1.67532 8.60765 1.931 8.69625 2.21325L11.4594 10.6883H19.7599L22.5263 2.18079C22.5333 2.15908 22.541 2.13757 22.5492 2.11628C22.6488 1.85911 22.807 1.62872 23.0113 1.44344C23.3401 1.14445 23.7686 0.978706 24.2133 0.978706C24.6589 0.978706 25.0885 1.14521 25.4175 1.44551C25.4262 1.45343 25.4347 1.46147 25.4432 1.46962C25.6563 1.67547 25.8144 1.93136 25.9029 2.21386L28.9447 11.5683L30.5282 16.2526C30.5307 16.26 30.5332 16.2674 30.5355 16.2749C30.5385 16.2842 30.5414 16.2936 30.5441 16.303C30.6823 16.7771 30.6638 17.2831 30.4913 17.7459C30.3235 18.1961 30.0188 18.5821 29.6203 18.8498ZM23.0113 1.44344L23.0136 1.44138L23.8512 2.36669L23.009 1.44551L23.0113 1.44344ZM24.6887 3.29199L24.6933 3.28786C24.6918 3.28925 24.6902 3.29062 24.6887 3.29199Z" fill="white" />
                </svg>
            );
        case 'Moving':
            return (
                <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 1.275C0 0.570837 0.559644 0 1.25 0H20C20.6904 0 21.25 0.570837 21.25 1.275V17.85C21.25 18.5542 20.6904 19.125 20 19.125H1.25C0.559644 19.125 0 18.5542 0 17.85V1.275ZM2.5 2.55V16.575H18.75V2.55H2.5Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M18.75 7.65C18.75 6.94584 19.3096 6.375 20 6.375H25C25.3315 6.375 25.6495 6.50933 25.8839 6.74844L29.6339 10.5734C29.8683 10.8125 30 11.1368 30 11.475V17.85C30 18.5542 29.4404 19.125 28.75 19.125H20C19.3096 19.125 18.75 18.5542 18.75 17.85V7.65ZM21.25 8.925V16.575H27.5V12.0031L24.4822 8.925H21.25Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.875 19.125C5.83947 19.125 5 19.9813 5 21.0375C5 22.0937 5.83947 22.95 6.875 22.95C7.91053 22.95 8.75 22.0937 8.75 21.0375C8.75 19.9813 7.91053 19.125 6.875 19.125ZM2.5 21.0375C2.5 18.5729 4.45875 16.575 6.875 16.575C9.29125 16.575 11.25 18.5729 11.25 21.0375C11.25 23.5021 9.29125 25.5 6.875 25.5C4.45875 25.5 2.5 23.5021 2.5 21.0375Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M23.125 19.125C22.0895 19.125 21.25 19.9813 21.25 21.0375C21.25 22.0937 22.0895 22.95 23.125 22.95C24.1605 22.95 25 22.0937 25 21.0375C25 19.9813 24.1605 19.125 23.125 19.125ZM18.75 21.0375C18.75 18.5729 20.7088 16.575 23.125 16.575C25.5412 16.575 27.5 18.5729 27.5 21.0375C27.5 23.5021 25.5412 25.5 23.125 25.5C20.7088 25.5 18.75 23.5021 18.75 21.0375Z" fill="white" />
                </svg>
            );
        case 'Barber':
            return (
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.40781 5.617C7.02709 5.617 5.90781 6.73629 5.90781 8.117C5.90781 9.49772 7.02709 10.617 8.40781 10.617C9.78852 10.617 10.9078 9.49772 10.9078 8.117C10.9078 6.73629 9.78852 5.617 8.40781 5.617ZM3.40781 8.117C3.40781 5.35558 5.64638 3.117 8.40781 3.117C11.1692 3.117 13.4078 5.35558 13.4078 8.117C13.4078 10.8784 11.1692 13.117 8.40781 13.117C5.64638 13.117 3.40781 10.8784 3.40781 8.117Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.40781 20.617C7.02709 20.617 5.90781 21.7363 5.90781 23.117C5.90781 24.4977 7.02709 25.617 8.40781 25.617C9.78852 25.617 10.9078 24.4977 10.9078 23.117C10.9078 21.7363 9.78852 20.617 8.40781 20.617ZM3.40781 23.117C3.40781 20.3556 5.64638 18.117 8.40781 18.117C11.1692 18.117 13.4078 20.3556 13.4078 23.117C13.4078 25.8784 11.1692 28.117 8.40781 28.117C5.64638 28.117 3.40781 25.8784 3.40781 23.117Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M26.7917 4.73312C27.2798 5.22128 27.2798 6.01273 26.7917 6.50089L11.9417 21.3509C11.4535 21.839 10.6621 21.839 10.1739 21.3509C9.68576 20.8627 9.68576 20.0713 10.1739 19.5831L25.0239 4.73312C25.5121 4.24497 26.3035 4.24497 26.7917 4.73312Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M18.1106 17.8339C18.5983 17.3453 19.3898 17.3446 19.8784 17.8323L26.7909 24.7323C27.2795 25.22 27.2802 26.0115 26.7925 26.5001C26.3048 26.9887 25.5133 26.9894 25.0247 26.5017L18.1122 19.6017C17.6236 19.114 17.6229 18.3225 18.1106 17.8339Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.1739 9.88312C10.6621 9.39497 11.4535 9.39497 11.9417 9.88312L16.7917 14.7331C17.2798 15.2213 17.2798 16.0127 16.7917 16.5009C16.3035 16.989 15.5121 16.989 15.0239 16.5009L10.1739 11.6509C9.68576 11.1627 9.68576 10.3713 10.1739 9.88312Z" fill="white" />
                </svg>
            )
        case 'Security':
            return (
                <svg width="31" height="31" viewBox="0 0 31 31" fill="white" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.9441 1.9466C15.2271 1.84048 15.5389 1.84048 15.8219 1.9466L25.8219 5.6966C26.3098 5.87955 26.633 6.34596 26.633 6.86701V15.617C26.633 19.9251 23.7808 23.3674 21.2061 25.6202C19.8925 26.7696 18.5848 27.6681 17.608 28.2786C17.1183 28.5846 16.7084 28.8204 16.4181 28.9811C16.2728 29.0615 16.1573 29.1233 16.0763 29.1658C16.0359 29.187 16.004 29.2035 15.9814 29.215L15.9546 29.2287L15.9465 29.2328L15.9438 29.2342C15.9434 29.2344 15.942 29.235 15.383 28.117C14.824 29.235 14.8236 29.2349 14.8232 29.2347L14.8195 29.2328L14.8114 29.2287L14.7846 29.215C14.762 29.2035 14.7301 29.187 14.6896 29.1658C14.6087 29.1233 14.4932 29.0615 14.3479 28.9811C14.0576 28.8204 13.6477 28.5846 13.158 28.2786C12.1812 27.6681 10.8735 26.7696 9.55986 25.6202C6.98518 23.3674 4.133 19.9251 4.133 15.617V6.86701C4.133 6.34596 4.45621 5.87955 4.94409 5.6966L14.9441 1.9466ZM15.383 28.117L14.8232 29.2347C15.1751 29.4106 15.5901 29.411 15.942 29.235L15.383 28.117ZM15.383 26.6955C15.6225 26.5597 15.9289 26.3799 16.283 26.1586C17.1812 25.5972 18.3735 24.7769 19.5599 23.7388C21.9852 21.6166 24.133 18.809 24.133 15.617V7.73326L15.383 4.45201L6.633 7.73326V15.617C6.633 18.809 8.78081 21.6166 11.2061 23.7388C12.3925 24.7769 13.5848 25.5972 14.483 26.1586C14.8371 26.3799 15.1434 26.5597 15.383 26.6955Z" fill="white" />
                </svg>
            )
        case 'Add':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="white" className="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
            )
        case 'Book':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="white" className="feather feather-book"><path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path><path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path></svg>
            )
        case 'User':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="white" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
            )
        case 'Electrical':
            return (
                <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.76953 6.25C7.07917 6.25 6.51953 6.80964 6.51953 7.5V22.5C6.51953 23.1904 7.07917 23.75 7.76953 23.75H22.7695C23.4599 23.75 24.0195 23.1904 24.0195 22.5V7.5C24.0195 6.80964 23.4599 6.25 22.7695 6.25H7.76953ZM4.01953 7.5C4.01953 5.42893 5.69846 3.75 7.76953 3.75H22.7695C24.8406 3.75 26.5195 5.42893 26.5195 7.5V22.5C26.5195 24.5711 24.8406 26.25 22.7695 26.25H7.76953C5.69846 26.25 4.01953 24.5711 4.01953 22.5V7.5Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.2695 11.25C10.2695 10.5596 10.8292 10 11.5195 10H19.0195C19.7099 10 20.2695 10.5596 20.2695 11.25V18.75C20.2695 19.4404 19.7099 20 19.0195 20H11.5195C10.8292 20 10.2695 19.4404 10.2695 18.75V11.25ZM12.7695 12.5V17.5H17.7695V12.5H12.7695Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.5195 0C12.2099 0 12.7695 0.559644 12.7695 1.25V5C12.7695 5.69036 12.2099 6.25 11.5195 6.25C10.8292 6.25 10.2695 5.69036 10.2695 5V1.25C10.2695 0.559644 10.8292 0 11.5195 0Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M19.0195 0C19.7099 0 20.2695 0.559644 20.2695 1.25V5C20.2695 5.69036 19.7099 6.25 19.0195 6.25C18.3292 6.25 17.7695 5.69036 17.7695 5V1.25C17.7695 0.559644 18.3292 0 19.0195 0Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.5195 23.75C12.2099 23.75 12.7695 24.3096 12.7695 25V28.75C12.7695 29.4404 12.2099 30 11.5195 30C10.8292 30 10.2695 29.4404 10.2695 28.75V25C10.2695 24.3096 10.8292 23.75 11.5195 23.75Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M19.0195 23.75C19.7099 23.75 20.2695 24.3096 20.2695 25V28.75C20.2695 29.4404 19.7099 30 19.0195 30C18.3292 30 17.7695 29.4404 17.7695 28.75V25C17.7695 24.3096 18.3292 23.75 19.0195 23.75Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M24.0195 11.25C24.0195 10.5596 24.5792 10 25.2695 10H29.0195C29.7099 10 30.2695 10.5596 30.2695 11.25C30.2695 11.9404 29.7099 12.5 29.0195 12.5H25.2695C24.5792 12.5 24.0195 11.9404 24.0195 11.25Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M24.0195 17.5C24.0195 16.8096 24.5792 16.25 25.2695 16.25H29.0195C29.7099 16.25 30.2695 16.8096 30.2695 17.5C30.2695 18.1904 29.7099 18.75 29.0195 18.75H25.2695C24.5792 18.75 24.0195 18.1904 24.0195 17.5Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.269531 11.25C0.269531 10.5596 0.829175 10 1.51953 10H5.26953C5.95989 10 6.51953 10.5596 6.51953 11.25C6.51953 11.9404 5.95989 12.5 5.26953 12.5H1.51953C0.829175 12.5 0.269531 11.9404 0.269531 11.25Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.269531 17.5C0.269531 16.8096 0.829175 16.25 1.51953 16.25H5.26953C5.95989 16.25 6.51953 16.8096 6.51953 17.5C6.51953 18.1904 5.95989 18.75 5.26953 18.75H1.51953C0.829175 18.75 0.269531 18.1904 0.269531 17.5Z" fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="30" height="30" fill="#223E5C" transform="translate(0.269531)" />
                        </clipPath>
                    </defs>
                </svg>
            )
            case 'Import':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="white"  className="feather feather-log-in">
                        <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                        <polyline points="10 17 15 12 10 7"></polyline>
                        <line x1="15" y1="12" x2="3" y2="12"></line>
                    </svg>
                )
        default:
            return (
                <svg width="31" height="33" viewBox="0 0 31 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.2139 0.315045C14.7555 -0.105015 15.514 -0.105015 16.0557 0.315045L29.5557 10.7843C29.9211 11.0676 30.1348 11.5033 30.1348 11.9648V28.4164C30.1348 29.6064 29.6607 30.7476 28.8167 31.5891C27.9728 32.4305 26.8282 32.9032 25.6348 32.9032H4.63477C3.44129 32.9032 2.2967 32.4305 1.45279 31.5891C0.608871 30.7476 0.134766 29.6064 0.134766 28.4164V11.9648C0.134766 11.5033 0.348475 11.0676 0.713855 10.7843L14.2139 0.315045ZM3.13477 12.6963V28.4164C3.13477 28.8131 3.2928 29.1935 3.57411 29.474C3.85541 29.7545 4.23694 29.912 4.63477 29.912H25.6348C26.0326 29.912 26.4141 29.7545 26.6954 29.474C26.9767 29.1935 27.1348 28.8131 27.1348 28.4164V12.6963L15.1348 3.39032L3.13477 12.6963Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.13477 16.4516C9.13477 15.6256 9.80634 14.956 10.6348 14.956H19.6348C20.4632 14.956 21.1348 15.6256 21.1348 16.4516V31.4076C21.1348 32.2336 20.4632 32.9032 19.6348 32.9032C18.8063 32.9032 18.1348 32.2336 18.1348 31.4076V17.9472H12.1348V31.4076C12.1348 32.2336 11.4632 32.9032 10.6348 32.9032C9.80634 32.9032 9.13477 32.2336 9.13477 31.4076V16.4516Z" fill="white" />
                </svg>
            );
    }


}

export default MovieIcons;